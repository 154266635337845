import React, { useEffect, useState, useRef } from 'react';
import { renderAmount } from '../../../utils';
import { Swiper, SwiperSlide } from 'swiper/react';
import Link from 'next/link';
import Router from 'next/router';
import Image from 'next/image'
import showAllImg from '../../../assets/images/showall.png'

// import SwiperCore, {
//   Lazy
// } from 'swiper';

// SwiperCore.use([Lazy]);

const Carousel = ({ settings,  data, categories, isShowAll }) => {
  const imgRef = useRef([])
  useEffect(() => {
    if (window !== undefined) {
      imgRef.current.forEach(el => {
        el.style.height = `${el.getBoundingClientRect().width}px`;
      })
    }
  })
  const defaultSettings = {
    spaceBetween: 10,
    freeMode: true,
    resizeObserver: true,
    // lazy:true,
    breakpoints: {
      "1200": {
        slidesPerView: 5.5
      },
      "992": {
        slidesPerView: 4.5
      },
      "768": {
        slidesPerView: 4.5
      },
      "576": {
        slidesPerView: 3.5
      },
      "0": {
        slidesPerView: 2.5
      },
    },
    ...(settings || {})
  };

  const addToRefs = el => {
    if (el && !imgRef.current.includes(el)) {
      imgRef.current.push(el);
    }
   };

  const renderItem = (item, i) => {
    return (
      <SwiperSlide key={item.id}>
        <div className="card flash-sale-card" onClick={() => Router.push(`/products/${item.slug}`)}>
            <div className='flash-sale-image' ref={addToRefs}>
              <Image loading={"lazy"}  layout="fill"  src={`${item.picture}?size=200`} alt={item.title}/>
              {
                item?.soldOut && (
                  <div className="overlayProduct">
                  Tạm hết hàng
                </div>
                )
              }
            </div>
            <div className="card-body p-1 pb-3 flash-sale-info">
                <span className="product-title">{item.title}</span>
                <p className="sale-price text-custom-primary">
                  {renderAmount(item.price)}
                </p>
            </div>
        </div>
      </SwiperSlide>
    )
  }

  return (
    <div className="flash-sale-wrapper mt-3">
      <div className="container p-0 px-1 bg-white pb-1">
        <div className="section-heading d-flex align-items-center justify-content-between m-0 px-2">
          <div className="title">
            {categories.name.toUpperCase()}
          </div>
          {isShowAll ? <a className="btn btn-outline-custom-primary btn-sm text-custom-primary" href={`/categories/${categories.slug}`} >Xem tất cả</a>  : null}
        </div>
        <Swiper {...defaultSettings}>
          {data.map((e, key) => renderItem(e))}
          {isShowAll ?
            <SwiperSlide>
              <div className="card flash-sale-card" onClick={() => Router.push(`/categories/${categories.slug}`)}>
                <div style={{ paddingBottom: '100%', position: 'relative'}}>
                <Image  layout="fill" loading={"lazy"} src={showAllImg} alt={`Hiện sản phẩm của ${categories.name.toUpperCase()}`}/>
                </div>
                
                <div className="card-body p-1  pb-3  flash-sale-info">
                    <span className="product-title text-center text-custom-primary">Xem thêm</span>
                    <p className="sale-price text-custom-primary">
                    </p>
                </div>
              </div>
            </SwiperSlide>
         : null}
        </Swiper>
      </div>

    </div>
  )
}


export default Carousel;