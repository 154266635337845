import React from 'react';
import Slick from 'react-slick';
import Image from 'next/image'


const Slider = ({ settings,  data }) => {
  const renderDots = (dots) => {
    return <div>
      {dots.map(dot  => <div key={dot.key} className={`owl-dot ${dot.props.className === 'slick-active' ? 'active' : ''}`} onClick={dot.props.children.props.onClick}><span></span></div>)}
    </div>
  }
  const defaultSettings = {
    dots: true,
    dotsClass: 'owl-dots',
    className: "hero-slides",
    appendDots: renderDots,
    lazyLoad: "ondemand",
    arrows: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    ...(settings || {})
  };

  const renderItem = (item, key) => <div key={key}>
    <div {...(item.onClick ? { onClick: item.onClick } : {})} className="single-hero-slide" >
      <Image priority layout="fill" alt="" src={item.url}/>
    </div>
  </div>
  return (
    <div className="container p-0">
      <div className="slider-wapper" >
        <Slick {...defaultSettings}>
          {data.map((e, key) => renderItem(e, key))}
        </Slick>

      </div>
    </div>
  )
}

export default Slider;