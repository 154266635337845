import React from 'react';
import Link from 'next/link'
import Image from 'next/image'
import image from '../../assets/images/image.png'

const FooterDetail = () => (
  <div className="container">
    <div className="footerDetail">
      <div className="footerDetail_customer row">
          <div className='col-lg-3 col-md-4 col-6'><Link href="/about/introduction">Giới thiệu</Link></div>
          <div className='col-lg-3 col-md-4 col-6'><Link href="/customer-support/order-guide">Hướng dẫn đặt hàng</Link></div>
          <div className='col-lg-3 col-md-4 col-6'><Link href="/customer-support/ship-policy">Chính sách vận chuyển</Link></div>
          <div className='col-lg-3 col-md-4 col-6'><Link href="/customer-support/warranty-policy">Chính sách bảo hành</Link></div>
          <div className='col-lg-3 col-md-4 col-6'><Link href="/about/term-condition">Điều khoản & điều kiện</Link></div>
          <div className='col-lg-3 col-md-4 col-6'><Link href="/about/contact">Liên hệ</Link></div>
      </div>
    </div>
    <div className="subFooter">
      <h1>VĂN PHÒNG PHẨM TÂN TIẾN</h1>
      <div className="footerDetail_wrapImage text-center">
        <a rel="noreferrer" target="_blank" href="http://online.gov.vn/(X(1)S(gijoqndu4lb3q1obktnbdcjs))/Home/WebDetails/38363?AspxAutoDetectCookieSupport=1">
          <Image className="footerDetail_image" width={60} height={23} src={image} alt="Đã thông báo bộ công thương" />
        </a>
      </div>
      <p>
        15E Nguyễn Thị Minh Khai, P.Bến Nghé, Quận 1, Tp.HCM 
        <br/>
        Cửa hàng mở cửa: 7h30 - 17h45 mỗi ngày trừ Chủ Nhật.
      </p>
      <p className="linked">
        <span>Tel: <a href="tel:02839118357">(028) 39.118.357</a> - Tel/Zalo: <a href="tel:0903760903">0903760903</a></span>
        <br/>
        Email:<a href="mailto:vanphongphamtantien@gmail.com">vanphongphamtantien@gmail.com</a>
      </p>
    </div>
  </div>
);

export default FooterDetail;
