import React from 'react';

import FooterDetail from '../components/layouts/FooterDetail'
import { fetchCarouselHome, fetchProductHome } from '../services/request-ssr';
import Head from 'next/head';
import Carousel from '../components/modules/carousel';
import Slider from '../components/modules/slider';
import Script from 'next/script';

const Home = ({ dataSlider, dataCategory }) => {
  return (
    <div>
      <Head>
        <title>Văn phòng phẩm Tân Tiến</title>
        <meta name="description" content={process.env.description} />
        <link rel="canonical" href={process.env.host} />
      </Head>
      <Script
        src="https://www.googletagmanager.com/gtag/js?id=G-KNNQQD2R2J"
        strategy="afterInteractive"
      />
      <Script id="google-analytics" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-KNNQQD2R2J');
        `}
      </Script>
      <div className="" >
        <div id="content-home">
          <div className='slider-wapper'>
            <Slider data={dataSlider} />
          </div>
          {
            dataCategory.map(({ products, ...category }) =>
              <Carousel key={category.id} data={products} categories={category} isShowAll={true} />
            )
          }
        </div>
      </div>
      <FooterDetail />

    </div>
  )
}
export async function getServerSideProps({ res }) {
  const [dataSlider, dataCategory] = await Promise.all([
    fetchCarouselHome(),
    fetchProductHome()
  ])

  return {
    props: {
      dataSlider,
      dataCategory
    }
  }
}
export default Home